import { getChannels } from "../api/YoutubeService";
import { useState, useEffect } from "react";
import ListChannelsComponent from "./youtube/ListChannelsComponent";
import AddChannelComponent from "./youtube/AddChannelComponent";
import { useAuth } from "../security/AuthContext";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./util/LoadingComponent";
import aiAssistant from "../assets/cute_robot.png"

export default function WelcomeComponent() {

  const navigate                = useNavigate();
  const auth                    = useAuth();
  const [channels, setChannels] = useState();

  useEffect(() => {
    // if (! auth.checkIfSubscribed()) {
    //   navigate("/settings");
    // } else {
      reloadChannels();
    // }
  }, []);

  function reloadChannels() {
    getChannels()
    .then(response => {
      setChannels(response.data)
      auth.setChannels(response.data);
    })
    .catch(error => console.log(error))
  }

  function selectedChannel(channel) {
    navigate({
      pathname: "/channel",
      search:   `channel=${channel.channelId}`
    });
  }

  function addedChannel(channel) {
    if (channel != null) {
      navigate({
        pathname: "/channel",
        search:   `channel=${channel.channelId}`
      });
    }
  }

  return (
        <div className="WelcomeComponent">

        <img className="welcome-ai mt-4" src={aiAssistant} alt="" />
          { ((channels == null) || (channels?.length === 0)) && <h1 className="mt-4">Welcome to Creator AI Tools!</h1>}
          
          { channels == null && <LoadingComponent />}
          { channels?.length > 0 && <ListChannelsComponent channels={channels} channelSelected={selectedChannel}/>}

          <div className="pt-5 mb-2 text-center d-flex justify-content-center">
            
              { (channels?.length === 0) && <>
                        <div>The AI needs access to your YouTube channel to begin. Tap the button below to sign-in with YouTube.</div>
                    </> }
                  { (channels?.length > 0) && "Connect another YouTube channel by tapping below." }
              
            
          </div>
          <AddChannelComponent callback={addedChannel}/>
        </div>
  )
}