import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../security/AuthContext";
import { GOOGLE_AUTH_URL } from "../../constants/constants";
import googleLogo from "../../assets/google-logo.png";
import "../css/Login.css";
import LoadingWaveComponent from "../util/LoadingWaveComponent";
import LoadingSmallComponent from "../util/LoadingSmallComponent";

export default function LoginComponent() {
    // STATE
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggingIn, setLoggingIn] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(null);

    // OTHER HOOKS
    const navigate = useNavigate();
    const authContext = useAuth();

    useEffect(() => {
        if (authContext.isAuthenticated) {
            navigate('/welcome/');
        }
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();

        setShowErrorMessage(null);
        let errors = [];

        // Check if email is valid
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(username)) {
            errors.push("Please enter a valid email. ");
        }

        // Check if password is at least 10 characters long
        if (password.length < 10) {
            errors.push("Password must be at least 10 characters long.");
        }

        if (errors.length > 0) {
            setShowErrorMessage(errors);
            return;
        }

        setLoggingIn(true);
        const result = await authContext.login(username, password)

        if (result.success) {
            navigate("/welcome/");
            setLoggingIn(false);
        } else {
            setShowErrorMessage(result.error);
            setLoggingIn(false);
        }
    }

    return (
        <div className="login-container container flex-grow-1 d-flex align-items-center justify-content-center">
            <div className="login-content">
                <h1 className="login-title">Login to Creator AI Tools</h1>

                <SocialLogin />

                <div className="or-separator">
                    <span className="or-text">OR</span>
                </div>

                <form className="LoginForm" onSubmit={handleSubmit}>
                    <div className="form-item">
                        <input
                            className="form-control"
                            placeholder="Email"
                            type="text"
                            name="username"
                            autoComplete="username"
                            value={username}
                            onChange={(e) =>
                                setUsername(e.target.value)
                            }></input>
                    </div>
                    <div className="form-item">
                        <input
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) =>
                                setPassword(e.target.value)
                            }></input>
                    </div>
                    {showErrorMessage && (
                        <div className="errorMessage">
                            {showErrorMessage}
                        </div>
                    )}
                    <div className="form-item">
                        <button
                            className="button button-block button-primary"
                            type="submit"
                            name="login"
                            disabled={isLoggingIn}>
                            {isLoggingIn ? <LoadingSmallComponent /> : "Login"}
                        </button>
                    </div>
                </form>

                <span className="signup-link">
                    New user? <Link to="/signup">Sign up!</Link>
                </span>
            </div>
        </div>
    );
}

function SocialLogin() {
    return (
        <div className="social-login">
            <a
                className="button button-block social-button google"
                href={GOOGLE_AUTH_URL}>
                <img src={googleLogo} alt="Google" />
                Login with Google
            </a>
        </div>
    );
}
