export default function ListChannelsComponent({channels, channelSelected}) {

    return (
        <div className="container">
            <h1 className="m-4">Your Channels</h1>
            <div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                  {
                      channels.map(
                        channel => (
                              <tr className="align-middle" key={channel.id} onClick={() => channelSelected(channel)}>
                                  <td><img src={channel.imageUrl} alt="channel thumbnail" 
                                  width="80" height="80"
                                  className="rounded-circle"/></td>
                                  <td>{channel.name}</td>
                              </tr>
                          )
                      )
                  }
                    </tbody>
                </table>
          </div>
      </div>
    )
}
