import { useEffect } from "react";
import robot from "../../assets/cute_robot.png";
import { Link } from "react-router-dom";
import { useAuth } from '../../security/AuthContext';

export default function ThankYouComponent() {
    const authContext = useAuth();
    
    useEffect(() => {
        const transactionId = `${authContext.currentUserId}-${new Date().toISOString().split('T')[0]}`;
    
        window.gtag('event', 'conversion', { 
            'send_to': 'AW-944641462/PxshCIX1yskZELaruMID', 
            'transaction_id': transactionId 
        });
    }, [authContext.currentUserId]);

    return (
        <div className="ThankYouComponent container h-100">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex align-items-center m-5">
                    <img
                        className="mt-4 mb-3 intelligent-robot"
                        src={robot}
                        alt=""></img>

                    <div className="m-5">
                        <h1 className="mt-5">Thank You!</h1>

                        <p className="m-2 small-text page-description">
                            We want to improve the product based on your feedback. Please use the Contact link below at any time to send us your thoughts.
                        </p>
                        <p className="m-2 small-text page-description">
                            <Link to={`/welcome`}>
                                {'→ '}Get Started{' ←'}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
