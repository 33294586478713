import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSmallComponent from "../util/LoadingSmallComponent";
import { useEffect, useState } from "react";
import { getChannelVideo } from "../../api/YoutubeService";

export default function CommentComponent({
    comment,
    callIgnoreComment,
    isAllCommentsMode,
}) {
    const videoLoadedInitializer =
        comment.videoThumbnail != null && comment.videoTitle != null;
    const [isVideoLoaded, setIsVideoLoaded] = useState(videoLoadedInitializer);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const channelId = searchParams.get("channel");
    const formattedTime = moment.utc(comment.publishedAt).fromNow();

    function tappedVideo() {
        var videoId = videoIdForComment(comment);

        if (comment.videoId == null) {
            getChannelVideo(channelId, videoId)
                .finally(() => {
                    goToVideo(videoId);
                });
        } else {
            goToVideo(videoId);
        }
    }

    function goToVideo(videoId) {
        navigate({
            pathname: "/comments",
            search: `channel=${channelId}&video=${videoId}`,
        });
    }

    function videoIdForComment(comment) {
        var videoId = comment.videoId;
        if (videoId == null) {
            videoId = comment.unverifiedVideoId;
        }
        return videoId;
    }

    useEffect(() => {
        if (
            !isVideoLoaded &&
            (comment.unverifiedVideoId != null || comment.videoId != null)
        ) {
            var videoId = videoIdForComment(comment);

            if (videoId != null) {
                getChannelVideo(channelId, videoId)
                    .then((response) => {
                        comment.videoThumbnail = response.data.imageUrl;
                        comment.videoTitle = response.data.title;
                        setIsVideoLoaded(true);
                    })
                    .catch((error) => {
                        console.error("Error loading video for comment", error);
                    });
            }
        }
    }, []);

    return (
        <div className="commentsTableColumn2 card bg-light">
            {/* if comment.videoTitle is not set or comment.videoThumbnail, 
                    show spinner
                    load,   (use the new getChannelVideo api call)
                        set their values and reload this */}
            {isAllCommentsMode && (
                <>
                    <div className="thumbnail-comment-header justify-content-center align-items-center">
                        {isVideoLoaded ? (
                            <>
                                <img
                                    onClick={tappedVideo}
                                    className="clickable thumbnail-comment-image m-2"
                                    src={comment.videoThumbnail}
                                    alt="Video thumbnail"
                                />
                                <div
                                    onClick={tappedVideo}
                                    className="clickable thumbnail-comment-title">
                                    {comment.videoTitle}
                                </div>
                            </>
                        ) : (
                            <LoadingSmallComponent />
                        )}
                    </div>
                </>
            )}

            <div className="card-header d-flex justify-content-between">
                <h5 className="flex-1 card-title">
                    {comment.authorDisplayName}
                </h5>
                <div className="flex-1">{formattedTime}</div>
                <button
                    type="button"
                    className="btn btn-danger flex-1"
                    aria-label="Ignore Comment"
                    onClick={callIgnoreComment}
                    style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        textAlign: "center",
                        padding: "0",
                    }}>
                    X
                </button>
            </div>
            <div className="card-body">
                <p className="card-text">{comment.textOriginal}</p>
            </div>
            {}
        </div>
    );
}
