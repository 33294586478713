import LoadingWaveComponent from "../util/LoadingWaveComponent";
import AutoresizingTextArea from "../util/AutoresizingTextArea";

export default function CommentReplyComponent({ reply, replyTypeTitle, regenerate, editedReply }) {

  return (
    <div className="commentsTableColumn4">
      {reply != null ? (
        <div className="card bg-light">
          <div className="card-header d-flex align-middle justify-content-between">
            <h5 className="card-title flex-1">{replyTypeTitle}</h5>
            <button
              type="button"
              className="btn btn-warning flex-1"
              aria-label="Regenerate Reply"
              onClick={regenerate}
            >Regenerate</button>
          </div>
          <div className="card-body">
            <AutoresizingTextArea inputText={reply} onChange={editedReply} />
          </div>
        </div>
      ) : (
        <LoadingWaveComponent></LoadingWaveComponent>
      )}
    </div>
  );
}
