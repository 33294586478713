import { apiClient } from "./ApiClient";

export const getChannels
    = () =>
        apiClient.get("/channels",
        {})

export const getChannel
= (channelId) =>
    apiClient.get(`channels/${channelId}`,
    {})

export const getChannelVideo
= (channelId, videoId) =>
    apiClient.get(`channels/${channelId}/videos/${videoId}`,
    {})

export const getVideos = (channelId, nextPageToken) => {
    let params = {};
    if (nextPageToken) {
      params.nextPageToken = nextPageToken;
    }
    return apiClient.get(`channels/${channelId}/videos`, { params });
  };
  
export const getCachedVideos
    = (channelId) =>
        apiClient.get(`channels/${channelId}/cached-videos`,
        {})

export const getVideoComments
    = (channelId, videoId) =>
        apiClient.get(`channels/${channelId}/videos/${videoId}/comments`,
        {})

export const getCachedVideoComments
    = (channelId, videoId) =>
        apiClient.get(`channels/${channelId}/videos/${videoId}/cached-comments`,
        {})

export const getAllComments
    = (channelId) =>
        apiClient.get(`channels/${channelId}/comments`,
        {})

export const getCommentReply
    = (channelId, comment) =>
        apiClient.get(`channels/${channelId}/comments/${comment.commentId}/aiReply`,
        {})

export const regenerateCommentReply
    = (channelId, comment) =>
        apiClient.get(`channels/${channelId}/comments/${comment.commentId}/regenerateAiReply`,
        {})

export const postCommentReply
    = async (channelId, comment, text) =>
        apiClient.post(`channels/${channelId}/comments/${comment.commentId}/reply`,
        { text })

export const ignoreComment
    = (channelId, comment) =>
        apiClient.post(`channels/${channelId}/comments/${comment.commentId}/ignore`,
        {})

export const getSettings
    = (channelId) =>
        apiClient.get(`channels/${channelId}/settings`,
        {})

export const postSettings
    = async (channelId, settings) =>
        apiClient.post(`channels/${channelId}/settings`,
        {...settings})

export const checkSettings
    = (channelId) =>
        apiClient.get(`channels/${channelId}/settings-check`, {});
