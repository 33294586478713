import { useEffect, useState } from 'react';
import styles from '../css/landingpage.module.css';
import { useAuth } from '../../security/AuthContext';

import { Button, Modal } from "react-bootstrap";

import { deleteAccount, loadUserStripeCheckoutURL, loadUserStripeInfo } from '../../api/AuthService';
import moment from 'moment';
import LoadingSmallComponent from '../util/LoadingSmallComponent';
import { useNavigate } from 'react-router-dom';

export default function SubscriptionComponent() {

    const auth = useAuth();

    const navigate = useNavigate();

    const [stripePortalURL,     setStripePortalURL]      = useState(null);
    const [nextBillDate,        setNextBillDate]         = useState(null);
    const [subscriptionStatus,  setSubscriptionStatus]   = useState("");
    const [totalTokenCount,     setTotalTokenCount]      = useState(0);
    const [isLoadingURL,        setIsLoadingURL]         = useState(false);

    let formattedNextBillDate = "";
    if (nextBillDate != null) {
        formattedNextBillDate = moment.utc(nextBillDate).fromNow();
    }

    useEffect(() => {
        if (auth.checkIfSubscribed()) {
            loadUserStripeInfo()
                .then((response) => {
                    setStripePortalURL(response.data.portalURL);
                    setNextBillDate(response.data.nextBillDate);
                    setSubscriptionStatus(response.data.subscriptionStatus);
                    setTotalTokenCount(response.data.totalTokenCount);
                })
                .catch((error) => {});
        }
    }, []);

    async function openCheckout() {
        setIsLoadingURL(true);
        try {
            const response = await loadUserStripeCheckoutURL();
            const stripeCheckoutURL = response.data.url;
            window.location = stripeCheckoutURL;
        } catch (error) {
            // Handle error
            console.error("Failed to load Stripe checkout URL", error);
            setIsLoadingURL(true);
        }
    }

    function updatePaymentMethodClicked() {
        window.open(stripePortalURL, "_blank");
    }

    function performAccountDelete() {
        deleteAccount()
        .then((response) => {
            auth.logout();
            navigate("/");
        })
        .catch((error) => {
            alert('Error deleting account, please email contact@creatoraitools.com');
        });
    }

    function signout() {
        auth.logout();
        navigate("/login");
    }

    return (
        <>
            {auth.checkIfSubscribed() && (
                <section>
                    {stripePortalURL == null && <LoadingSmallComponent />}

                    {stripePortalURL != null && (
                        <>
                            <div className="bg-light settings-section border rounded">
                                <h5 className="mt-3">ACCOUNT</h5>
                                <div className="divider mb-4"></div>

                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <p>
                                        Email:{" "}
                                        <strong>{auth.currentUserEmail}</strong>
                                    </p>
                                    <p>
                                        Status:{" "}
                                        <strong>
                                            {subscriptionStatus.toUpperCase()}
                                        </strong>
                                    </p>
                                    <p>
                                        Total Tokens Used:{" "}
                                        <strong>{totalTokenCount}</strong>
                                    </p>
                                    <Button
                                        className="m-2 p-3"
                                        variant="primary"
                                        onClick={signout}>
                                        Sign Out
                                    </Button>
                                </div>
                            </div>

                            <br className="mt-5" />

                            <div className="bg-light settings-section border rounded">
                                <h5 className="mt-3">SUBSCRIPTION</h5>
                                <div className="divider mb-4"></div>

                                <div className="mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <p className="">
                                        We partner with Stripe for simplified
                                        billing. Tap the button below to go to
                                        your Stripe customer portal. Your
                                        subscription is {subscriptionStatus}
                                        {subscriptionStatus === "active"
                                            ? ", and your next billing is " +
                                              formattedNextBillDate +
                                              "."
                                            : "."}
                                    </p>
                                    <Button
                                        className="m-2 p-3"
                                        variant="primary"
                                        onClick={updatePaymentMethodClicked}>
                                        Manage Subscription
                                    </Button>
                                </div>
                            </div>

                            <br className="mt-5" />

                            <div className="bg-light settings-section border rounded">
                                <h5 className="mt-3">PRIVACY</h5>
                                <div className="divider mb-4"></div>
                                <div className="mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <p className="">
                                        In accordance with GDPR, use this button
                                        to delete your account and wipe all data
                                        from our servers forever.
                                    </p>
                                    <DeleteButtonComponent
                                        performAccountDelete={
                                            performAccountDelete
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </section>
            )}
            {!auth.checkIfSubscribed() && (
                <section className={`${styles["marketing-section-4"]} p-0`}>
                    <h1 className={`${styles["small-h1"]} pt-4`}>
                        Pricing Plans
                    </h1>
                    <div
                        className={`${styles["pricing-options"]} py-4 mt-5 rounded border border-white`}>
                        <h2 className={`${styles["pricing-name"]} mt-4`}>
                            MEMBER
                        </h2>

                        <p className={`${styles["small-pricing-label"]} m-2`}>
                            <span className={"text-danger " + styles["big-number"]}><del>19</del></span>
                            <del className='text-danger'>.99 /mo.</del>
                            <br />
                            <div className="text-warning">Early Adopter Discount!</div>
                            
                            <span className={styles["big-number"]}>9</span>
                            .99 /mo.
                        </p>
                        <p>Up to 1,500,000 Tokens</p>
                        <p>(about 3,000 Replies /mo.)</p>
                        <p>Unlimited Channels</p>
                        <button
                            onClick={openCheckout}
                            className={`${styles["call-to-action"]} m-4 p-1 border border-white`}>
                            {isLoadingURL ? (
                                <LoadingSmallComponent />
                            ) : (
                                <h4>
                                    <span className="mobile-only">
                                        Tap Here
                                    </span>
                                    <span className="non-mobile-only">
                                        Click Here
                                    </span>
                                    to Purchase
                                </h4>
                            )}
                        </button>
                    </div>
                </section>
            )}
        </>
    );
}

function DeleteButtonComponent({performAccountDelete}) {
    const [deleteTapCount, setDeleteTapCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [deleteText, setDeleteText] = useState('');

    const handleModalClose = () => {
        setShowModal(false);
        setDeleteText(''); // reset the input text
    };

    const handleConfirmDeletion = () => {
        if (deleteText === 'DELETE') {
            // Perform the deletion logic here
            performAccountDelete();
            handleModalClose();
        } else {
            // Handle error or show a message that the input is incorrect
        }
    };

    let content;

    switch (deleteTapCount) {
        case 0:
            content = (
                <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setDeleteTapCount(deleteTapCount + 1)}>
                    Delete this Account
                </span>
            );
            break;
        case 1:
            content = (
                <Button
                    className="m-2 p-2"
                    style={{ backgroundColor: "red", borderColor: "red" }}
                    onClick={() => setDeleteTapCount(deleteTapCount + 1)}>
                    <strong>DELETE ACCOUNT</strong>
                </Button>
            );
            break;
        case 2:
            content = (
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                        className="m-2 p-3"
                        style={{ backgroundColor: "red", borderColor: "red" }}
                        variant="primary"
                        onClick={() => setShowModal(true)}>
                        <strong>CONFIRM DESTRUCTION</strong>
                    </Button>
                    <p>
                        <small className="text-warning">
                            Final warning! Once you click this button there's no
                            going back. All account data is lost forever. {" "}
                            <span
                                style={{ color: "blue", cursor: "pointer" }}
                                onClick={() => setDeleteTapCount(0)}>
                                 Nevermind!
                            </span>
                        </small>
                    </p>

                    <Modal show={showModal} onHide={handleModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm account deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Enter the text 'DELETE' and hit confirm to delete your account forever.
                            <input 
                                type="text" 
                                value={deleteText} 
                                onChange={(e) => setDeleteText(e.target.value)} 
                                className="form-control mt-2"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleModalClose}>
                                Close
                            </Button>
                            <Button variant="danger" onClick={handleConfirmDeletion}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    
                </div>
            );
            break;

        default:
            content = null;
            break;
    }

    return content;
}