import { useEffect, useRef } from "react";

export default function AutoresizingTextArea({ inputText , onChange, placeholder, maxLength}) {
  const textAreaRef = useRef(null);

  useEffect(() => {
    // Seems to need to wait a millisecond to properly resize on intial load
    setTimeout(adjustHeight, 1);
    setTimeout(adjustHeight, 100);
  }, []);

  // const adjustHeight = () => {
  //   const textArea = textAreaRef.current;
  //   if (textArea) {
  //       textArea.style.height = "auto";
  //       textArea.style.height = textArea.scrollHeight + "px";
  //   }
  // };

  const adjustHeight = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
        let isUsingPlaceholder = false;
        
        if (!inputText) {
            textArea.value = placeholder;
            isUsingPlaceholder = true;
        }

        textArea.style.height = "auto";
        textArea.style.height = textArea.scrollHeight + "px";

        if (isUsingPlaceholder) {
            textArea.value = "";
        }
    }
};


  function handleChange(event) {
    onChange(event.target.value);
    adjustHeight();
  }

  return (
      <textarea
          ref={textAreaRef}
          onChange={handleChange}
          className="border-0"
          placeholder={placeholder}
          maxLength={maxLength}
          style={{
              width: "100%",
              height: "auto",
              resize: "none",
              overflow: "hidden",
          }}
          value={inputText}></textarea>
  );
}
