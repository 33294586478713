import CommentRowComponent from "./CommentRowComponent";
import CommentsFooter from "./CommentsFooter";
import CommentReplyTableHeader from "./CommentReplyTableHeader";

export default function ListCommentsComponent({comments = [], ...otherProps}) {

  const headerNames = ["", "Comment", "", "Reply", "Approve"]
  
    return (
      <div className="">
        <CommentReplyTableHeader tableInfo={headerNames}/>
        <div className="CommentsTable">
          {
            comments?.map(
              comment => (
                <CommentRowComponent key={comment.id} comment={comment} {...otherProps}/>
              ))
          }
        </div>
        <CommentsFooter totalCommentCount={comments.length} className="commentsFooter" {...otherProps}/>
      </div>
    )
  }
