import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import LoginComponent from './components/signin/LoginComponent';
import LogoutComponent from './components/signin/LogoutComponent';
import SignupComponent from './components/signin/SignupComponent';
import WelcomeComponent from './components/WelcomeComponent';
import NavBarComponent from './components/overall/NavBarComponent';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import AuthProvider, { useAuth } from './security/AuthContext'
import OauthRedirectComponent from './oauth2/OauthRedirectComponent';
import ChannelComponent from './components/ChannelComponent';

import { GoogleOAuthProvider } from '@react-oauth/google';
import VideoComponent from './components/VideoComponent';
import CommentsComponent from './components/comments/CommentsComponent';
import MarketingComponent from './components/marketing/MarketingComponent';
import SubscriptionComponent from './components/marketing/SubscriptionComponent';
import FooterComponent from './components/overall/FooterComponent';
import PrivacyComponent from './components/overall/PrivacyComponent';
import TermsComponent from './components/overall/TermsComponent';
import { useEffect, useState } from 'react';
import LoadingWaveComponent from './components/util/LoadingWaveComponent';
import { performInitialUserLoading, hasAuthenticationToken } from './api/AuthService';

import confusedRobot from './assets/confused_robot.png'
import ThankYouComponent from './components/marketing/ThankYouComponent';

function AuthenticatedRoute({ children }) {
  const authContext = useAuth();
  if (authContext.isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/login" />
  }
}

function App() {

  const [isLoading, setIsLoading] = useState(hasAuthenticationToken()); 

  const [isAuthenticated, setIsAuthenticated] = useState(false); 
  const [currentUser,     setCurrentUser]     = useState(null); 

  function loadCurrentlyLoggedInUser() {
    performInitialUserLoading()
        .then(response => {
          let user = response.data.user;
          user.subscriptionStatus = response.data.subscriptionStatus;
          setCurrentUser(user);
            setIsAuthenticated(true);
        })
        .catch(error => {
            setIsAuthenticated(false);
        })
        .finally(function () {
              setIsLoading(false);
        }); 
  }

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  if (isLoading) {
    return (
      <LoadingWaveComponent />
    )
  }

  return (
    <div className="App d-flex flex-column vh-100">
      <AuthProvider isAuthenticated={isAuthenticated} currentUser={currentUser} setIsAuthenticated={setIsAuthenticated} setCurrentUser={setCurrentUser}>
        <GoogleOAuthProvider clientId="224121727292-b7hb1n03jjg1rt86gdg0be6h46ploc8p.apps.googleusercontent.com">
      
          <BrowserRouter>
            <NavBarComponent />

            <div className="d-flex flex-column flex-grow-1">
              <Routes>

                <Route path="/" element={ <MarketingComponent /> }/>
                <Route path="/oauth2/redirect" element={ <OauthRedirectComponent/> } />
                <Route path="/login" element={ <LoginComponent /> }/>
                <Route path="/signup" element={ <SignupComponent /> }/>
                <Route path="/privacy" element={ <PrivacyComponent /> }/>
                <Route path="/terms" element={ <TermsComponent /> }/>
                
                <Route path="/logout" element={ 
                  <AuthenticatedRoute>
                    <LogoutComponent />
                  </AuthenticatedRoute>}/>
                
                <Route path="/thank-you" element={ 
                  <AuthenticatedRoute>
                    <ThankYouComponent />
                  </AuthenticatedRoute>}
                />
                  
                <Route path="/welcome" element={ 
                  <AuthenticatedRoute>
                    <WelcomeComponent />
                  </AuthenticatedRoute>}
                />

                <Route path="/settings" element={ 
                  <AuthenticatedRoute>
                    <SubscriptionComponent />
                  </AuthenticatedRoute>}
                />
                  
                <Route path="/channel" element={
                  <AuthenticatedRoute>
                  <ChannelComponent />
                </AuthenticatedRoute>}/>

                <Route path="/video" element={
                  <AuthenticatedRoute>
                  <VideoComponent />
                </AuthenticatedRoute>}/>

                <Route path="/comments" element={
                  <AuthenticatedRoute>
                  <CommentsComponent />
                </AuthenticatedRoute>}/>
                
                <Route path="*" element={<ErrorComponent />}/>
                <Route path="/error" element={<ErrorComponent />}/>
              </Routes>
            </div>
            <FooterComponent />

          </BrowserRouter>

        </GoogleOAuthProvider>
      </AuthProvider>
    </div>
  ) 
}

export default App;
 

function ErrorComponent() {
  return (
    <div className="ErrorComponent m-5">
    {/* <h1>Error</h1> */}
      <img className="confused-robot" src={confusedRobot} alt=""></img>
      <div>The AI says "110010100" (a.k.a. 404)</div>
    </div>
  )
}