import { useAuth } from "../../security/AuthContext";
import { useGoogleLogin } from '@react-oauth/google';
import youtubeLogo from "../../assets/youtube_logo.png"

export default function AddChannelComponent({ callback }) {

    const auth = useAuth();
    
    const signIn = useGoogleLogin({
        onSuccess: response =>  {
            auth.completedFrontendYoutubeLogin(callback, response.code, response.scope, response.expires_in);
        },
        onFailure: response =>  {
            callback(null);
        },

        flow: 'auth-code',
        // scope:'https://www.googleapis.com/auth/youtube.force-ssl email profile openid',
        scope:'https://www.googleapis.com/auth/youtube.force-ssl',
    });

    return (
        <button className="btn btn-dark text-decoration-none link-light p-3" onClick={() => signIn()}>
            <img alt="YouTube" src={youtubeLogo}></img>
        </button>
    )
}