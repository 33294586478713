import { useEffect, useState } from "react"
import { useNavigate, Link } from 'react-router-dom'
import { useAuth } from '../../security/AuthContext'
import { GOOGLE_AUTH_URL } from "../../constants/constants"
import googleLogo from '../../assets/google-logo.png';
import '../css/Login.css';

import ReCAPTCHA from "react-google-recaptcha";
import LoadingSmallComponent from "../util/LoadingSmallComponent";

export default function SignupComponent() {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [recaptchaResponse, setRecaptchaResponse] = useState(null);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [isLoggingIn, setLoggingIn] = useState(false);

    const [errorMessages, setErrorMessages] = useState(null);

    const navigate = useNavigate();

    const authContext = useAuth();

    useEffect(() => {
        if (authContext.isAuthenticated) {
            navigate('/welcome/');
        }
    }, []);

    async function handleSubmit(isGoogleSignup) {
        setErrorMessages(null);
        let errors = [];
    
        // Check if reCAPTCHA is ready
        if (!recaptchaResponse) {
            errors.push("Please complete the reCAPTCHA.");
        }
    
        if (!acceptTerms) {
            errors.push( "You must accept the Terms and Conditions and Privacy Policy.");
        }
    
        if (!isGoogleSignup) {
            if (password !== confirmPassword) {
                errors.push("Passwords do not match.");
            }
    
            // Check if email is valid
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!emailRegex.test(username)) {
                errors.push("Please enter a valid email.");
            }
    
            // Check if password is at least 10 characters long
            if (password.length < 10) {
                errors.push("Password must be at least 10 characters long.");
            }
        }
    
        if (errors.length > 0) {
            setErrorMessages(errors);
            return;
        }
    
        if (isGoogleSignup) {
            window.location.href = GOOGLE_AUTH_URL;
        } else {
            setLoggingIn(true);
            const result = await authContext.signup(
                username,
                password,
                recaptchaResponse
            );
    
            if (result.success) {
                navigate("/welcome/");
            } else {
                errors.push(result.error);
                setErrorMessages(errors);
                setLoggingIn(false);
            }
            // } else {
            //     setErrorMessages([result.error]);
            //     setLoggingIn(false);
            // }
        }
    }
    
    // async function handleSubmit() {
    //     setErrorMessages(null);
    //     let errors = [];
    //     if (password !== confirmPassword) {
    //         errors.push("Passwords do not match.");
    //     }

    //     // Check if email is valid
    //     const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    //     if (!emailRegex.test(username)) {
    //         errors.push("Please enter a valid email.");
    //     }

    //     // Check if password is at least 8 characters long
    //     if (password.length < 10) {
    //         errors.push("Password must be at least 10 characters long.");
    //     }

    //     // Check if reCAPTCHA is ready
    //     if (!recaptchaResponse) {
    //         errors.push("Please complete the reCAPTCHA.");
    //     }

    //     if (!acceptTerms) {
    //         errors.push(
    //             "You must accept the Terms and Conditions and Privacy Policy."
    //         );
    //     }

    //     if (errors.length > 0) {
    //         setErrorMessages(errors);
    //         return;
    //     }


    //     setLoggingIn(true);
    //     const result = await authContext.signup(
    //         username,
    //         password,
    //         recaptchaResponse
    //     );

    //     if (result.success) {
    //         navigate("/welcome/");
    //     } else {
    //         setErrorMessages([result.error]);
    //         setLoggingIn(false);
    //     }
    // }

    return (
      <div className="login-container container flex-grow-1 d-flex align-items-center justify-content-center">

        <div className="login-content m-4">
            <h1 className="login-title">Create an Account</h1>

            <SocialLogin handleSubmit={handleSubmit} />

            <div className="or-separator">
                <span className="or-text">OR</span>
            </div>

            <div className="LoginFor m-4">  
                <div className="form-item">
                    <input className="form-control" placeholder="Email" type="text" name="username" autoComplete="username" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                </div>    
                <div className="form-item"> 
                    <input className="form-control"  placeholder="Password" type="password" name="password" autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                </div>  
                <div className="form-item"> 
                    <input className="form-control"  placeholder="Confirm Password" type="password" name="confirmPassword" autoComplete="new-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></input>
                </div>    

                <div className="form-item d-flex align-items-center">
                    <input type="checkbox" className="signup-checkbox" id="acceptTerms" name="acceptTerms" value={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
                    <label className="small-signup-text" htmlFor="acceptTerms">I accept the <Link to="/terms" target="_blank">Terms</Link> and <Link to="/privacy" target="_blank">Privacy Policy</Link>, as well as <a href="https://www.youtube.com/t/terms">YouTube's Terms</a></label>
                </div>

                <ReCAPTCHA sitekey="6Lcc5SgnAAAAACxuuFtD9UcdiWqQjZ8nAVE_bOSv" onChange={setRecaptchaResponse}/>

                {errorMessages?.length > 0 && errorMessages.map((message, index) => (
                    <div key={index} className="errorMessage mt-2">{message}</div>
                ))}
                
                <div className="form-item mt-3">
                    <button className="button button-block button-primary" type="button" name="login" disabled={isLoggingIn} onClick={() => handleSubmit(false)}>
                        {isLoggingIn ? <LoadingSmallComponent/> : "Sign up"}
                    </button>
                </div>
            </div>

            <span className="signup-link">Have an account? <Link to="/login">Login!</Link></span>
        </div>
      </div>
    )
  }

// function SocialLogin() {
//     return (
//         <div className="social-login">
//             <a className="button button-block social-button google" href={GOOGLE_AUTH_URL}>
//                 <img src={googleLogo} alt="Google" />Sign Up with Google</a>
//         </div>
//     );
// }

function SocialLogin({ handleSubmit }) {
    return (
        <div className="social-login">
            <button
                className="button button-block social-button google"
                onClick={() => handleSubmit(true)}>
                <img src={googleLogo} alt="Google" />Sign Up with Google
            </button>
        </div>
    );
}


