// ProgressBar.js
import React, { useState, useEffect } from 'react';

export default function ProgressBar() {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPosition = window.scrollY;
    const scrollPercentage = (scrollPosition / totalHeight) * 100;
    setScroll(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="progressBarContainer">
      <div className="progressBar" style={{ width: `${scroll}%` }}></div>
    </div>
  );
};
