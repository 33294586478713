export default function LogoutComponent() {  

    return (
      <div className="LogoutComponent">
        <h1>You are logged out!</h1>
        <div>
            Thank you for using our app. Come back soon!
        </div>
      </div>
    )
  }