import ReconnectChannelComponent from "../youtube/ReconnectChannelComponent";

export default function ErrorLoadingComponent({error, resolvedCallback}) {
    return (
        <div className="m-5">
            <div>{error.response.data.message}</div>
            { (error.response.status === 511) && 
                <ReconnectChannelComponent callback={resolvedCallback}/>
            }           
        </div>
    )
}
