import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    getVideoComments,
    getCachedVideoComments,
    postCommentReply,
    getAllComments,
    ignoreComment,
    checkSettings
} from "../../api/YoutubeService";
import ListCommentsComponent from "./ListCommentsComponent";
import LoadingWaveComponent from "../util/LoadingWaveComponent";
import ErrorLoadingComponent from "../util/ErrorLoadingComponent";

import "../css/CommentComponent.css";
import ConfirmSendComponent from "./ConfirmSendComponent";

import { correctWordForPluralAndCount } from "../util/util.js";
import { Button, Modal } from "react-bootstrap";
import donePic from "../../assets/done.png"
import youtubeIcon from "../../assets/youtube_social_icon_red.png";
import ProgressBar from "../util/ProgressBar";
import ChannelSettingsModal from "../ChannelSettingsModal";
import { useAuth } from "../../security/AuthContext.js";

export default function CommentsComponent() {
    const [comments, setComments] = useState(null);

    const navigate = useNavigate();
    const [error, setError] = useState();
    const [approvedRepliesCount, setApprovedRepliesCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [needsInitialLoad, setNeedsInitialLoad] = useState(false);

    const [inProgress, setInProgress] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [commentToIgnore, setCommentToIgnore] = useState(null);

    const [searchParameters] = useSearchParams();
    const channelId = searchParameters.get("channel");
    const videoId = searchParameters.get("video");

    const location = useLocation();
    const auth     = useAuth();

    const isAllCommentsMode = videoId == null;

    useEffect(() => {
        if (channelId == null) {
            navigate("/welcome/");
        } else {
            // check if settings are set
            checkSettings(channelId)
                .then((response) => {
                    let settingsReady = response.data;
                    if (settingsReady) {
                        if (auth.checkIfSubscribed()) {
                            setComments(null);
                            reload();
                        } else {
                            navigate("/settings/")    
                        }
                    } else {
                        setNeedsInitialLoad(true);
                        setShowSettings(true);
                    }
                })
                .catch((error) => {
                    console.error("Error checking settings:", error);
                });
        }
    }, [location.search]);

    useEffect(() => {
        approvalHasChanged();
        scrollToTop();
    }, [comments]);

    function reload() {
        if ( !auth.checkIfSubscribed()) {
            navigate("/settings/")    
            return;
        }

        setError(null);
        if (isAllCommentsMode) {
            getAllComments(channelId)
                .then((response) => {
                    receivedCommentsReply(response);
                })
                .catch((error) => {
                    setError(error);
                });
        } else {
            getVideoComments(channelId, videoId)
                .then((response) => {
                    receivedCommentsReply(response);
                })
                .catch((error) => {
                    setError(error);
                    getCachedVideoComments(channelId, videoId).then(
                        (response) => {
                            receivedCommentsReply(response);
                        }
                    );
                });
        }
    }

    function receivedCommentsReply(response) {
        let receivedComments = response.data;

        if (comments != null) {
            updateReceivedComments(receivedComments, comments);
        }
        setComments(receivedComments);
    }

    function updateReceivedComments(receivedComments, comments) {
        receivedComments.forEach((receivedComment) => {
            const matchingComment = comments.find(
                (comment) => comment.id === receivedComment.id
            );
            if (matchingComment) {
                receivedComment.isApproved = matchingComment.isApproved;
                receivedComment.proposedReply = matchingComment.proposedReply;
            }
        });
    }

    function approvalHasChanged() {
        let approvalCount = 0;

        if (comments != null) {
            comments.forEach((comment) => {
                approvalCount += comment.isApproved ? 1 : 0;
            });
        }

        setApprovedRepliesCount(approvalCount);
    }

    function showConfirm() {
        setShowModal(true);
    }

    function handleCloseModal() {
        if (inProgress === false) {
            setShowModal(false);
        }
    }

    function handleOpenSettings() {
        setShowSettings(true);
    }

    function handleCloseSettings() {
        setShowSettings(false);
        if (needsInitialLoad) {
            setComments(null);
            setNeedsInitialLoad(false);
            reload();
        }
    }

    async function sendReplies() {
        let approvedComments = comments.filter((comment) => comment.isApproved);
        let successfulReplies = [];

        if (approvedComments.length < 1) {
            return;
        }

        setInProgress(true);
        setModalMessage(
            "Posted " +
                successfulReplies.length +
                " of " +
                approvedComments.length +
                " replies"
        );

        for (const comment of approvedComments) {
            await postCommentReply(channelId, comment, comment.proposedReply)
                .then((response) => {
                    successfulReplies.push(comment);
                    setModalMessage(
                        "Posted " +
                            successfulReplies.length +
                            " of " +
                            approvedComments.length +
                            " replies"
                    );
                })
                .catch((error) => {
                    // unsuccessul reply
                    setError(error);
                });
        }

        if (successfulReplies.length === comments.length) {
            setComments(null);
            reload();
        } else {
            const unfinishedComments = comments.filter(
                (comment) => !successfulReplies.includes(comment)
            );
            setComments(unfinishedComments);
        }

        // at end
        setInProgress(false);
        setShowModal(false);
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const sendTitle =
        "Send " +
        approvedRepliesCount +
        " " +
        correctWordForPluralAndCount("Reply", "replies", approvedRepliesCount);

    async function performIgnore() {
        const comment = commentToIgnore;

        ignoreComment(channelId, comment)
            .then((response) => {
                const unfinishedComments = comments.filter(
                    (iteractedComment) => iteractedComment !== comment
                );
                setComments(unfinishedComments);
                setCommentToIgnore(null);
            })
            .catch((error) => {
                // TODO ERROR
                setCommentToIgnore(null);
                // unsuccessul reply
            });
    }

    async function ignore(comment) {
        setCommentToIgnore(comment);
    }

    function handleCloseIgnoreConfirmation() {
        setCommentToIgnore(null);
    }

    return (
        <div className="CommentsComponent">
            <Modal
                centered
                show={commentToIgnore}
                onHide={handleCloseIgnoreConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Ignore Comment?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    If you ignore this comment, it will never be shown again.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseIgnoreConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={performIgnore}>
                        Ignore
                    </Button>
                </Modal.Footer>
            </Modal>

            <ChannelSettingsModal
                show={showSettings}
                onClose={handleCloseSettings}
            />

            {error && (
                <ErrorLoadingComponent
                    error={error}
                    resolvedCallback={reload}></ErrorLoadingComponent>
            )}

            <ProgressBar />
            {comments === null && error === null && (
                <LoadingWaveComponent></LoadingWaveComponent>
            )}

            {comments?.length > 0 && (
                <>
                    {videoId != null && (
                        <div className="p-2 bg-light border-bottom  thumbnail-comment-header justify-content-center align-items-center">
                            {comments[0].videoThumbnail && (
                                <div className="image-container">
                                    <a
                                        href={`https://www.youtube.com/watch?v=${comments[0].videoId}`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img
                                            src={youtubeIcon}
                                            alt=""
                                            className="overlay-image"></img>
                                        <img
                                            src={comments[0].videoThumbnail}
                                            alt="Video thumbnail"
                                            className="thumbnail-comment-image base-image"
                                        />
                                    </a>
                                </div>
                            )}
                            <div className="m-2 thumbnail-comment-title">
                                {comments[0].videoTitle}
                            </div>
                        </div>
                    )}
                    <ListCommentsComponent
                        sendReplies={showConfirm}
                        comments={comments}
                        approvedRepliesCount={approvedRepliesCount}
                        approvalHasChanged={approvalHasChanged}
                        ignore={ignore}
                        channelId={channelId}
                        handleOpenSettings={handleOpenSettings}
                        isAllCommentsMode={
                            isAllCommentsMode
                        }></ListCommentsComponent>
                    <ConfirmSendComponent
                        showModal={showModal}
                        handleCancel={handleCloseModal}
                        handleConfirm={sendReplies}
                        inProgress={inProgress}
                        progressMessage={modalMessage}
                        title="Ready to Send?"
                        submitMessage={sendTitle}
                    />
                </>
            )}

            {comments?.length === 0 && error === null && (
                <div className="m-5">
                    <img className="done-pic" src={donePic} alt=""></img>
                    <h1>Well done!</h1>You've answered all the comments.
                </div>
            )}
        </div>
    );
}