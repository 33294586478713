import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ListVideosComponent from "./youtube/ListVideosComponent";
import { getVideos, getCachedVideos } from "../api/YoutubeService";
import ErrorLoadingComponent from "./util/ErrorLoadingComponent";
import LoadingWaveComponent from "./util/LoadingWaveComponent";
import { Button } from "react-bootstrap";
import smartRobot from '../assets/intelligent_robot.png'

export default function ChannelComponent() {
    const navigate = useNavigate();
    const [videos, setVideos] = useState();
    const [error, setError] = useState();
    const [searchParams] = useSearchParams();
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasReachedEnd, setReachedEnd] = useState(false);

    const channelId = searchParams.get("channel");

    useEffect(() => {
        if (channelId == null) {
            navigate("/welcome/");
        } else {
            reloadVideos();
        }
    }, []);

    function reloadVideos() {
        setError(null);
        getVideos(channelId)
            .then((response) => {
                setVideos(response.data.videos);
                setNextPageToken(response.data.nextPageToken);
                if (response.data.nextPageToken == null) {
                    setReachedEnd(true);
                }
            })
            .catch((error) => {
                setError(error);
                console.log('Failed to load videos from YouTube API, loading cached videos');
                getCachedVideos(channelId).then((response) => {
                    setVideos(response.data.videos);
                });
            });
    }

    function onVideoClick(video) {
        navigate({
            pathname: "/comments",
            search: `channel=${channelId}&video=${video.youtubeVideoId}`,
        });
    }

    function allCommentsClicked() {
        navigate({
            pathname: "/comments",
            search: `channel=${channelId}`,
        });
    }

    function onMoreClick() {
        setIsLoadingMore(true);
        getVideos(channelId, nextPageToken)
            .then((response) => {
                if (response.data.videos.length) {
                    setVideos((prevVideos) => [
                        ...prevVideos,
                        ...response.data.videos,
                    ]);
                    setNextPageToken(response.data.nextPageToken);
                    if (response.data.nextPageToken == null) {
                        setReachedEnd(true);
                    }
                } else {
                    setReachedEnd(true);
                }
                setIsLoadingMore(false);
            })
            .catch((error) => {
                setIsLoadingMore(true);
                setError(false);
            });
    }

    return (
        <div className="ChannelComponent container h-100">
            {error && (
                <ErrorLoadingComponent
                    error={error}
                    resolvedCallback={reloadVideos}></ErrorLoadingComponent>
            )}

            <div className="d-flex flex-column justify-content-center align-items-center">
                <h1 className="mt-5">Reply to Comments</h1>
                <div className="d-flex align-items-center">
                    <img className="mt-4 mb-3 intelligent-robot" src={smartRobot} alt=""></img>
                    <p className="m-5 small-text page-description">
                        Click below to <Link to={`/comments?channel=${channelId}`}>view all unanswered comments</Link> for your channel, or select a specific video farther down.
                    </p>
                </div>
            </div>

            <h2 className="m-4">All Videos</h2>
            <Button
                className="m-2 p-3"
                variant="primary"
                onClick={allCommentsClicked}>
                Find Unanswered Comments
            </Button>

            {videos != null && (
                <>
                    <h2 className="m-4 mt-5">By Video</h2>
                    <ListVideosComponent
                        videos={videos}
                        onVideoClick={onVideoClick}
                        onMoreClick={onMoreClick}
                        hasReachedEnd={hasReachedEnd}
                        isLoadingMore={isLoadingMore}></ListVideosComponent>
                </>
            )}

            {videos == null && error === null && (
                <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                        <div className="centeredDiv">
                            <LoadingWaveComponent />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
