import { createContext, useContext, useState } from "react";
import { apiClient } from "../api/ApiClient";
import { loadUserFromAPI, performLogin, performSignup, uploadYoutubeLogin } from "../api/AuthService";
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../constants/constants";
import { googleLogout } from '@react-oauth/google';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children, isAuthenticated, currentUser, setIsAuthenticated, setCurrentUser }) {

    // State
    const [channels, setChannels]  = useState(null)

    let currentUserEmail = "";
    if (currentUser !== null) {
        currentUserEmail = currentUser.email;
    }

    let currentUserId = "";
    if (currentUser !== null) {
        currentUserId = currentUser.id;
    }

    async function setupToken(accessToken) {
        localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, accessToken);
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        setIsAuthenticated(true)

        await reloadUser();
    }

    async function reloadUser() {
        try {
            const response = await loadUserFromAPI();
            let user = response.data.user;
            user.subscriptionStatus = response.data.subscriptionStatus;
            setCurrentUser(user);
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
        }
    }

    function removeToken() {
        localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
        delete apiClient.defaults.headers.common["Authorization"];
    }

    async function login(username, password) {

        try {
            const response = await performLogin(username, password)

            if(response.status === 200){
                await setupToken(response.data.accessToken);

                return { success: true};             
            } else {
                logout()
                return { success: false, error: "Login Failed" };
            }    
        } catch(error) {
            logout()
            if (error.response && error.response.data && error.response.data.message) {
                return { success: false, error: error.response.data.message };
            } else {
                return { success: false, error: "Login Failed" };
            }
        }
    }

    async function signup(username, password, recaptchaResponse, acceptMarketing) {

        try {
            const response = await performSignup(username, password, recaptchaResponse, acceptMarketing)

            if(response.status === 200) {
                await setupToken(response.data.accessToken);

                return { success: true};            
            } else {
                logout()
                return { success: false, error: "Signup Failed" };
            }    
        } catch(error) {
            logout()
            if (error.response && error.response.data && error.response.data.message) {
                return { success: false, error: error.response.data.message };
            } else {
                return { success: false, error: "Signup Failed" };
            }
        }
    }

    async function loggedInWithGoogle(accessToken) {
        await setupToken(accessToken);
    }

    async function completedFrontendYoutubeLogin(callback, code, scope, expires_in) {
        try {
            const response = await uploadYoutubeLogin(code, scope, expires_in)

            if(response.status === 200){
                callback(response.data)            
            } else {
                callback(null)
            }    
        } catch(error) {
            callback(null)
        }
    }

    function logout() {
        removeToken();

        setIsAuthenticated(false)
        setChannels(null);
        setCurrentUser(null);

        googleLogout();
    }

    function channelForId(id) {
        if (channels) {
            return channels.find(channel => channel.channelId === id);
        }
        return null;
    }

    function checkIfSubscribed()  {
        return (currentUser != null) && currentUser.subscriptionStatus === "active";
    }

    return (
        <AuthContext.Provider value={ {isAuthenticated, 
                                            login, signup, loggedInWithGoogle, logout,
                                            completedFrontendYoutubeLogin,
                                            setChannels, channelForId, 
                                            currentUserEmail, currentUserId, 
                                            checkIfSubscribed, reloadUser} }>
            {children}
        </AuthContext.Provider>
    )
}