import { useEffect, useState } from "react";
import { correctWordForPluralAndCount } from "../util/util.js"

// import gear from "../../assets/gear2.png";
import { Button } from "react-bootstrap";

export default function CommentsFooter({
    sendReplies,
    approvedRepliesCount,
    totalCommentCount,
    handleOpenSettings,
}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures the effect runs once when the component mounts and cleans up when it unmounts

    let settingsButton = (
        <Button className="" variant="dark" onClick={handleOpenSettings}>
            Settings
        </Button>
    );

    let approvalCountDiv = (
        <div className="col d-flex align-items-center justify-content-center">
            You've approved {approvedRepliesCount} of {totalCommentCount}{" "}
            {correctWordForPluralAndCount(
                "reply",
                "replies",
                totalCommentCount
            )}
            .
        </div>
    );

    let sendButton = (
        <button className="btn btn-primary btn-lg" onClick={sendReplies}>
            Send {approvedRepliesCount}{" "}
            {correctWordForPluralAndCount(
                "Reply",
                "Replies",
                approvedRepliesCount
            )}
        </button>
    );

    return (
        <footer className="fixed-bottom bg-light border comment-footer">
            <div className="row commentsFooter">
                        <div className="d-flex col align-items-center justify-content-center">
                            {settingsButton}
                        </div>

                { isMobile ? (approvedRepliesCount > 0 ? (
                        <div className="m-2 col d-flex align-items-center justify-content-end">
                            {sendButton}
                        </div>
                    ) : (
                        <div className="m-2 col d-flex align-items-center justify-content-center">
                            {approvalCountDiv}
                        </div>
                    )
                    
                ) : (
                    <>
                        <div className="m-2 col d-flex align-items-center justify-content-end">
                            {approvalCountDiv}
                        </div>

                        <div className="m-2 col d-flex align-items-center justify-content-end">
                            {approvedRepliesCount > 0 && sendButton}
                        </div>
                    </>
                )}

            </div>
        </footer>
    );
}