import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Nav from 'react-bootstrap/Nav';
import { useAuth } from '../../security/AuthContext';
import { Link, useLocation, useSearchParams } from "react-router-dom";

import logo from '../../assets/cat_smiling_logo.png';
import logoutIcon from "../../assets/logout.png";
import icon1 from "../../assets/videos.png";
import icon2 from "../../assets/settings.png";
import icon3 from "../../assets/tv.png";
import { useEffect, useState } from 'react';
import { getChannel } from '../../api/YoutubeService';

export default function NavBarComponent() {

    const authContext = useAuth();
    const [searchParameters] = useSearchParams();

    const channelId = searchParameters.get('channel');
    const [currentChannel, setCurrentChannel] = useState(authContext.channelForId(channelId));

    const homeLink = authContext.isAuthenticated ? (channelId ? `channel?channel=${channelId}` : "/welcome") : "/";

    const location = useLocation();
    const isSignupPage = location.pathname === "/signup";

    useEffect(() => {
        if (currentChannel == null && channelId != null) {
            getChannel(channelId)
            .then( (response) => {
                setCurrentChannel(response.data);
            })
        }
    }, [channelId]);

  return (
      <Navbar className="border-bottom p-1 bg-light">
          <Container className="flex">
              <div className="flex-1 d-flex items-center">
                  <Link
                      className="navbar-brand  fw-bold text-black d-inline"
                      to={homeLink}>
                      <Navbar.Brand className="d-flex align-items-center justify-content-center">
                          <img alt="" src={logo} width="40" />
                          <div className="navbar-brand ms-2 fs-2 fw-bold text-black non-mobile-only-inline">
                              Creator AI Tools
                          </div>
                      </Navbar.Brand>
                  </Link>
                  {!authContext.isAuthenticated && (
                      <>
                          <Nav className="d-flex align-items-center justify-content-center">
                              <Link
                                  className="nav-link d-inline me-3 non-mobile-only"
                                  to="/">
                                  Home
                              </Link>
                              <Link
                                  className="nav-link d-inline"
                                  to="/#pricing">
                                  Pricing
                              </Link>
                          </Nav>
                      </>
                  )}
              </div>

              <div className="flex-1">
                  {authContext.isAuthenticated ? (
                      <Dropdown align="end" as={NavItem}>
                          <Dropdown.Toggle as={NavLink}>
                              {currentChannel ? (
                                  <img
                                      src={currentChannel.imageUrl}
                                      width="40"
                                      height="40"
                                      alt="channel thumbnail"
                                      className="rounded-circle"
                                  />
                              ) : (
                                  authContext.currentUserEmail
                              )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="top-content dropdown-menu">
                              {currentChannel ? (
                                  <div className="d-flex justify-content-start align-items-center">
                                      <img
                                          src={currentChannel.imageUrl}
                                          width="40"
                                          height="40"
                                          alt="channel thumbnail"
                                          className="rounded-circle m-2"
                                      />
                                      <div className="m-2">
                                          {currentChannel.name}
                                      </div>
                                  </div>
                              ) : (
                                  //   authContext.checkIfSubscribed() && (
                                  <div className="d-flex m-2 align-items-center">
                                      {authContext.currentUserEmail}
                                  </div>
                                  //   )
                              )}

                              {/* {authContext.checkIfSubscribed() && ( */}
                              <Dropdown.Divider></Dropdown.Divider>
                              {/* )} */}

                              {/* {authContext.checkIfSubscribed() && ( */}
                              <Link className="nav-link" to="/welcome">
                                  <Dropdown.Item
                                      as="div"
                                      className="d-flex justify-content-start align-items-center">
                                      <img
                                          src={icon3}
                                          width="24"
                                          height="24"
                                          alt="logout icon"
                                          className="me-3"
                                      />
                                      Channels
                                  </Dropdown.Item>
                              </Link>
                              {/* )} */}

                              {channelId != null && (
                                  <Link className="nav-link" to={homeLink}>
                                      <Dropdown.Item
                                          as="div"
                                          className="d-flex justify-content-start align-items-center">
                                          <img
                                              src={icon1}
                                              width="24"
                                              height="24"
                                              alt="logout icon"
                                              className="me-3"
                                          />
                                          Videos
                                      </Dropdown.Item>
                                  </Link>
                              )}

                              {/* {authContext.checkIfSubscribed() && ( */}
                              <Link className="nav-link" to="/settings">
                                  <Dropdown.Item
                                      as="div"
                                      className="d-flex justify-content-start align-items-center">
                                      <img
                                          src={icon2}
                                          width="24"
                                          height="24"
                                          alt="logout icon"
                                          className="me-3"
                                      />
                                      Settings
                                  </Dropdown.Item>
                              </Link>
                              {/* )} */}

                              {/* {authContext.checkIfSubscribed() && ( */}
                              <Dropdown.Divider></Dropdown.Divider>
                              {/* )} */}

                              <Dropdown.Item
                                  className="d-flex justify-content-start align-items-center"
                                  align="end"
                                  onClick={() => authContext.logout()}>
                                  <img
                                      src={logoutIcon}
                                      width="24"
                                      height="24"
                                      alt="logout icon"
                                      className="me-3"
                                  />
                                  <div>Sign out</div>
                              </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                  ) : (
                      <Link
                          className="nav-link justify-content-end"
                          to={isSignupPage ? '/login' : '/signup'}>
                          Login
                      </Link>
                  )}
              </div>
          </Container>
      </Navbar>
  );
}
