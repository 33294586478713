import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export default function VideoComponent() {
  
    const navigate = useNavigate();

    const [searchParameters] = useSearchParams();
    const channelId = searchParameters.get('channel');
    const videoId   = searchParameters.get('video');

    useEffect(() => {
        if (channelId == null || videoId == null) {
            navigate("/welcome/");
        } else {
            reload();
        }
      }, []);


    function reload() {
    
    }

    return (
        <div className="VideoComponent">
            <h1>The AI can help you with:</h1>
            <Link className="btn btn-success m-5 p-3 btn-lg" to="/comments">Replying to Comments</Link>
        </div>
    )
}