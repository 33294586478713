import "../css/Privacy.css";

export default function TermsComponent() {
    return (
        <div className="container d-flex justify-content-center">
            <div className="w-50 text-small">
                <h1 className="text-center pb-4">Terms Of Service</h1>
                <small className="policy">
                    <p>
                        Welcome to Creator AI Tools, an online service that
                        provides a platform for users to leverage artificial
                        intelligence in automating their content creation. By
                        accessing or using the Creator AI Tools website, you
                        agree to be bound by the following Terms of Service
                        ("Terms"). This document outlines the terms of service
                        ("Agreement") for the use of the Creator AI Tools
                        application and website ("Service"), operated by Daniel Anderson Software Consulting ("us", "we", or "our").
                    </p>

                    <h2>Services</h2>

                    <p>
                        Daniel Anderson Software Consulting provides a platform for users to
                        automate their content creation using artificial
                        intelligence. We do not guarantee the accuracy,
                        completeness, or usefulness of any information or data
                        provided by our platform. We are not responsible for any
                        damages resulting from the use or inability to use our
                        platform.
                    </p>

                    <h2>User Conduct</h2>

                    <p>
                        You are solely responsible for the content that you
                        create using Creator AI Tools. You agree not to use the
                        platform for any unlawful, harmful, or fraudulent
                        purposes. You also agree not to use the platform to post
                        or upload any content that infringes on the rights of
                        others, or that is offensive, obscene, or defamatory.
                    </p>

                   <h2>Privacy</h2>

                    <p>
                        Daniel Anderson Software Consulting respects your privacy and will only
                        collect and use your personal information in accordance
                        with our Privacy Policy. By using the Creator AI Tools
                        platform, you consent to the collection and use of your
                        personal information as described in our Privacy Policy.
                    </p>

                    <h2>Intellectual Property</h2>

                    <p>
                        Daniel Anderson Software Consulting and its licensors retain all right,
                        title, and interest in and to the platform, including
                        all intellectual property rights. You may not copy,
                        modify, distribute, or create derivative works based on
                        the platform, in whole or in part, without the express
                        written consent of Creator AI Tools.
                    </p>

                    <h2>Termination</h2>

                    <p>
                        We may terminate your access to the platform, without
                        cause or notice, which may result in the forfeiture and
                        destruction of all information associated with your
                        account. All provisions of these Terms that by their
                        nature should survive termination shall survive
                        termination, including, without limitation, ownership
                        provisions, warranty disclaimers, indemnity, and
                        limitations of liability.
                    </p>

                    <h2>Disclaimer of Warranties</h2>

                    <p>
                        The platform is provided "as is." Creator AI Tools and
                        its licensors make no representations or warranties of
                        any kind, express or implied, as to the operation of the
                        platform or the information, content, materials, or
                        products included on the platform. To the fullest extent
                        permissible by applicable law, Creator AI Tools
                        disclaims all warranties, express or implied, including,
                        but not limited to, implied warranties of
                        merchantability and fitness for a particular purpose.
                    </p>

                    <h2>Limitation of Liability</h2>

                    <p>
                        Creator AI Tools and its licensors shall not be liable
                        for any damages whatsoever arising out of or related to
                        the use of the platform, including, without limitation,
                        direct, indirect, incidental, special, consequential, or
                        punitive damages, whether in an action of contract,
                        statute, tort (including, without limitation,
                        negligence), or otherwise.
                    </p>

                    <h2>Indemnification</h2>

                    <p>
                        You agree to indemnify and hold Creator AI Tools, its
                        affiliates, officers, agents, and employees harmless
                        from any claim or demand, including reasonable
                        attorneys' fees, made by any third party due to or
                        arising out of your breach of these Terms or your
                        violation of any law or the rights of a third party.
                    </p>

                    <h2>Governing Law and Jurisdiction</h2>

                    <p>
                        These Terms of Service and any dispute or claim arising
                        out of or in connection with them or their subject
                        matter or formation (including non-contractual disputes
                        or claims) shall be governed by and construed in
                        accordance with the laws of the State of California,
                        without giving effect to any choice or conflict of law
                        provision or rule. Any legal suit, action or proceeding
                        arising out of or related to these Terms of Service or
                        the App shall be instituted exclusively in the federal
                        or state courts located in San Francisco, California.
                        You waive any and all objectionsto the exercise of
                        jurisdiction over you by such courts and to venue in
                        such courts.
                    </p>

                    <h2>Waiver and Severability</h2>

                    <p>
                        No waiver of by the Company of any term or condition set
                        forth in these Terms of Service shall be deemed a
                        further or continuing waiver of such term or condition
                        or a waiver of any other term or condition, and any
                        failure of the Company to assert a right or provision
                        under these Terms of Service shall not constitute a
                        waiver of such right or provision. If any provision of
                        these Terms of Service is held by a court or other
                        tribunal of competent jurisdiction to be invalid,
                        illegal, or unenforceable for any reason, such provision
                        shall be eliminated or limited to the minimum extent
                        such that the remaining provisions of the Terms of
                        Service will continue in full force and effect.
                    </p>

                    <h2>Entire Agreement</h2>

                    <p>
                        These Terms of Service and our Privacy Policy constitute
                        the entire agreement between you and Creator AI Tools
                        regarding the use of the App, superseding any prior
                        agreements between you and Creator AI Tools relating to
                        your use of the App.
                    </p>
                    <p>
                        By using the YouTube API through Creator AI Tools, you are agreeing to respect and be bound by the <a href="https://www.youtube.com/t/terms">YouTube Terms of Service.</a>
                    </p>

                    <h2>Changes to Terms of Service</h2>

                    <p>
                        We reserve the right, at our sole discretion, to add or
                        remove portions of these Terms of Service at any time.
                        It is your responsibility to check these Terms of
                        Service periodically for changes. Your continued use of
                        the App following the posting of changes to these Terms
                        of Service constitutes acceptance of those changes.
                    </p>

                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions about these Terms of Service,
                        please contact us at:&nbsp;
                        <a
                            href="mailto:contact@creatoraitools.com"
                            className="">
                            contact@creatoraitools.com
                        </a>
                    </p>
                    <ul>
                        <li>Daniel Anderson</li>
                        <li>8405 N Standard St.</li>
                        <li>Spokane, WA 99208</li>
                    </ul>
                    <p>
                        By using our website or services, you acknowledge that
                        you have read and understood these terms of service.
                    </p>
                    <p>
                        &copy; {new Date().getFullYear()} Daniel Anderson. All
                        Rights Reserved.
                    </p>
                </small>
            </div>
        </div>
    );
}
