import LoadingSmallComponent from '../util/LoadingSmallComponent'

export default function ListVideosComponent({videos, onVideoClick, onMoreClick, hasReachedEnd, isLoadingMore}) {

    return (
      <div className="container">
        <div>
          <table className="table table-hover">
            <thead>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td>Title</td>
                    <td className="non-mobile-only">Released</td> */}
                </tr>
            </thead>
            <tbody>
                {
                    videos?.map(
                        video => (
                            <tr key={video.id} onClick={() => onVideoClick(video)} >
                                <td className="videos-list-image"><img className="thumbnail-image" src={video.imageUrl} alt=" "/></td>
                                <td className="videos-list-title align-middle">
                                  <div className="">{video.title}</div>
                                </td>
                                <td className="videos-list-date align-middle">
                                  <div className="non-mobile-only">{video.published}</div>
                                </td>
                            </tr>
                        )
                    )
                }
                { !hasReachedEnd &&
                <tr key={10090001} onClick={() => onMoreClick()} >
                    <td className="videos-list-image"></td>
                    <td className="videos-list-title align-middle">
                      <div className="">{isLoadingMore ? <LoadingSmallComponent /> : <div>Load More<br/>{'\u2193'}</div>}</div>
                    </td>
                    <td className="videos-list-date align-middle">
                      <div className="non-mobile-only"></div>
                    </td>
                </tr>
                }
            </tbody>
          </table>

        </div>
      </div>
    )
  }