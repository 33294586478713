import { Link } from "react-router-dom";

import logo from '../../assets/cat_smiling_logo.png';

export default function FooterComponent() {
  return (
    <div className="bg-light py-1 border-top border-gray-100 d-flex justify-content-between align-items-center">
      {/* <div className=""> */}
        <img alt="" src={logo} width="40" className="ms-3"/>
        <small className="d-flex align-items-center justify-content-center">
            <Link className="small nav-link p-3" to="/privacy">Privacy</Link>
            <Link className="small nav-link p-3" to="/terms">Terms</Link>
            <a className="small nav-link p-3 me-3" href="mailto:contact@creatoraitools.com">Contact</a>
        </small>
        
      {/* </div> */}
    </div>
  );
}