import React from "react";
import "../css/LoadingComponent.css";

export default function LoadingSmallComponent() {
  return (
    <div className="spinner-container-small">
      <div className="loading-spinner-small">
      </div>
    </div>
  );
}