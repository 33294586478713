import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../constants/constants";
import { apiClient } from "./ApiClient";

export const performLogin
    = (email, password) =>
        apiClient.post("/auth/login",
        { email, password })

export const performSignup
    = (email, password, recaptchaResponse, acceptMarketing) =>
        apiClient.post("/auth/signup",
        { email, password, recaptchaResponse, acceptMarketing })

export const uploadYoutubeLogin
    = (code, scope, expires_in) =>
        apiClient.post("/users/youtube/refresh-login",
        { code, scope, expires_in })

export const performInitialUserLoading
    = () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    if(!accessToken) {
        return Promise.reject("No access token set.");
    } else {
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }

    return loadUserFromAPI();
}

export const loadUserFromAPI
= () => {
    return apiClient.get("/user/me");
}

export const loadUserStripeInfo
= () => {
    return apiClient.get("/stripe");
}

export const loadUserStripeCheckoutURL
= () => {
    return apiClient.get("/stripe/checkout");
}

export const hasAuthenticationToken = () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    return !!accessToken;
};

export const deleteAccount
    = (code, scope, expires_in) =>
        apiClient.delete("/user/delete")
