import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../security/AuthContext";
import { useEffect } from "react";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export default function OauthRedirectComponent() {

    let query = useQuery();
    const authContext = useAuth();
    const navigate = useNavigate();

    useEffect(() => {

    let token = query.get('token');
    let error = query.get('error');

        if(token) {
            authContext.loggedInWithGoogle(token)
            .then(response => {
                navigate('/welcome/');
              })
              .catch(error => console.log(error))
        } else {
            navigate('/error', error);
        }
    }, []);

    return (
        <div id="container">Logging in with Google...</div>
    )
}