import { useAuth } from "../../security/AuthContext";
import { useGoogleLogin } from '@react-oauth/google';
import youtubeLogo from "../../assets/youtube_logo.png"

export default function ReconnectChannelComponent({ callback }) {

    const auth = useAuth();
    
    const signIn = useGoogleLogin({
        onSuccess: response =>  {
            auth.completedFrontendYoutubeLogin(callback, response.code, response.scope, response.expires_in);
        },
        onFailure: response =>  {
            callback(null);
        },

        flow: 'auth-code',
        scope:'https://www.googleapis.com/auth/youtube.force-ssl',
        // scope:'https://www.googleapis.com/auth/youtube.force-ssl email profile openid',
    });

    return (
        <div className="ReconnectChannelComponent m-4">
            Re-Connect to YouTube by tapping below.
            <button className="btn btn-success text-decoration-none link-light p-3" onClick={() => signIn()}>
                <img alt="YouTube" src={youtubeLogo}></img>
            </button>
        </div>
    )
}
