import { useEffect, useState } from "react";
import ApproveComponent from "./ApproveComponent";
import ArrowComponent from "./ArrowComponent";
import CommentComponent from "./CommentComponent";
import CommentReplyComponent from "./CommentReplyComponent";
import { getCommentReply, regenerateCommentReply } from "../../api/YoutubeService";

export default function CommentRowComponent({comment, approvalHasChanged, ignore, channelId, isAllCommentsMode}) {
  const aiReplyTitle = "AI Generated Reply";
  const [reply, setReply] = useState();
  const [replyTypeTitle, setReplyTypeTitle] = useState(aiReplyTitle);
  
  useEffect(() => {
    if (comment.aiGeneratedReply === null) {
      generateAiReply(comment);
    } else {
      updateReplyText(comment.proposedReply || comment.aiGeneratedReply);
    }
  }, []);

  function generateAiReply(rowComment) {
    getCommentReply(channelId, rowComment)
      .then((response) => {
        updateReplyText(response.data.aiGeneratedReply);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function editedReply(text) {
    setReplyTypeTitle("You")
    updateReplyText(text);
    if (text.length === 0) {
        disapprove();
    }
  }

  function updateReplyText(text) {
    comment.proposedReply = text;
    setReply(text);
  }

  function disapprove() {
    comment.isApproved = false;
    approvalHasChanged();
  }

  function regenerate() {
    comment.aiGeneratedReply = null;
    disapprove();
    updateReplyText(null);
    setReplyTypeTitle(aiReplyTitle);
    regenerateCommentReply(channelId, comment)
      .then((response) => {
        updateReplyText(response.data.aiGeneratedReply);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function callIgnoreComment() {
    ignore(comment);
  }

    return (
        <div className="CommentRowComponent mt-5 mb-5 d-flex align-items-center justify-content-center">
            <div                   className="commentsTableColumn1" />
            <CommentComponent      className="commentsTableColumn2" comment={comment} callIgnoreComment={callIgnoreComment} isAllCommentsMode={isAllCommentsMode}/>
            <ArrowComponent        className="commentsTableColumn3" />
            <CommentReplyComponent className="commentsTableColumn4" comment={comment} reply={reply} replyTypeTitle={replyTypeTitle} regenerate={regenerate} editedReply={editedReply}/>
            {reply?.length > 0 ? <ApproveComponent      className="commentsTableColumn5" comment={comment} approvalHasChanged={approvalHasChanged}/>
                               : <div className="commentsTableColumn5"></div>}
        </div>

    )
}