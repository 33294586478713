import "../css/Privacy.css";

export default function PrivacyComponent() {
    return (
        <div className="container m-2 text-small policy">

        {/* <div className="container m-2 d-flex align-items-center justify-content-center text-small"> */}
            <h1 className="pb-4">Privacy Policy</h1>
            <p>
                Creator AI Tools, Inc. ("Creator AI Tools," "we," "us," "our")
                is committed to protecting your privacy. This privacy policy
                ("Policy") describes how we collect, use, and disclose
                information about you in connection with your use of Creator AI
                Tools' services ("Services"). By using the Services, you agree
                to the collection and use of information in accordance with this
                Policy.
            </p>
            <p>
                Creator AI Tools' use and transfer to any other app of
                information received from Google APIs will adhere to the{" "}
                <a
                    href="https://developers.google.com/terms/api-services-user-data-policy"
                    target="_blank"
                    rel="noreferrer">
                    Google's API Services User Data Policy
                </a>{" "}
                including the Limited Use requirements, and also{" "}
                <a
                    href="https://developers.google.com/youtube/terms/api-services-terms-of-service#1.-limited-use-requirements"
                    target="_blank"
                    rel="noreferrer">
                    YouTube's API Services Terms of Service
                </a>
                .
            </p>
            <h2>Information We Collect</h2>

            <p>
                (a) <b>User Data.</b> To provide our Services, we collect the
                following information from you: your name, email address, and
                high-level data on your YouTube channels and videos (e.g.,
                YouTube specific IDs associated with each of these entities,
                channel and video names, and descriptions). We do not store any
                other YouTube data within our database or any other specific
                user data.
            </p>

            <p>
                (b) <b>Comment Data.</b> We store the comments and replies that
                we've generated with our generative AI tooling, and the
                respective metadata that goes along with those comments.
            </p>

            <p>
                (c) <b>Access Token Data.</b> We store a refresh token that is
                used to refresh an access token when it has expired. This is
                stored securely and can only be accessed with a valid user
                session in place, and users can only access their own data.
            </p>

            <h2>How We Use Your Information</h2>

            <p>
                (a) <b>User Data.</b> We use your user data to provide our
                Services to you, including authentication, performing actions
                you have requested using the YouTube Data API, and for billing
                and subscription purposes.
            </p>

            <p>
                (b) <b>Comment Data.</b> We use the comment data to provide our
                Services to you, including generating replies to comments on
                your behalf.
            </p>

            <p>
                (c) <b>Access Token Data.</b> We use the access token data to
                authenticate you and perform the actions you have requested
                using the YouTube Data API.
            </p>

            <h2>How We Share Your Information</h2>

            <p>
                (a) <b>OpenAI ChatGPT API</b> OpenAI respects your privacy. In
                order to generate AI replies, we send your viewer's comments and
                your AI settings to OpenAI's ChatGPT API. OpenAI will not use
                your data for training models, or disclose your data. See
                OpenAI's commitments to privacy for their API here:{" "}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://openai.com/enterprise-privacy">
                    OpenAI's API Privacy Policy
                </a>
            </p>

            <p>
                (b) <b>YouTube Data API.</b> We share your information with the
                YouTube Data API to perform the actions you have requested.
            </p>

            <p>
                (c) <b>Service Providers.</b> We may engage third-party service
                providers to perform functions on our behalf, such as hosting,
                analytics, payment processing, and customer service. These
                service providers may have access to your information in order
                to perform their functions.
            </p>

            <p>
                (d) <b>Legal Requirements.</b> We may disclose your information
                if required to do so by law or in response to a subpoena, court
                order, or other valid legal process.
            </p>

            <h2>Personal Data</h2>
            <p>
                While using our Service, we may ask you to provide certain
                personally identifiable information that can be used to contact
                or identify you ("Personal Data"). Personally identifiable
                information may include:
            </p>
            <br />
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Usage Data</li>
            </ul>
            <br />
            <p>
                We may also collect logs on how the Service is accessed and used
                ("Usage Data"). This Usage Data may include information such as
                your computer's Internet Protocol address (e.g., IP address),
                browser type, browser version, the pages of our Service that you
                visit, and the time and date of your visit.
            </p>
            <h2>Tracking & Cookies Data</h2>
            <p>
                We use cookies, browser local storage, and similar technologies
                for authentication.
            </p>
            <br />
            <p>
                {" "}
                Cookies are files with small amounts of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device.
            </p>
            <br />
            <p>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
            </p>
            <br />
            <p>
                We use Security Cookies and Browser Local Storage for
                authentication.
            </p>
            <h2>Use of Data</h2>
            <p>
                Creator AI Tools uses the collected data for various purposes:
            </p>
            <br />
            <ul>
                <li>To provide and maintain our Service</li>
                <li>To notify you about changes to our Service</li>
                <li>
                    To allow you to participate in interactive features of our
                    Service when you choose to do so
                </li>
                <li>To provide customer care and support</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent, and address technical issues</li>
            </ul>
            <h2>
                Legal Basis for Processing Personal Data under the General Data
                Protection Regulation (GDPR)
            </h2>
            <p>
                If you are from the European Economic Area (EEA), Creator AI
                Tools' legal basis for collecting and using the personal
                information described in this Privacy Policy depends on the
                Personal Data we collect and the specific context in which we
                collect it.
            </p>
            <br />
            <p>Creator AI Tools may process your Personal Data because:</p>
            <br />
            <ul>
                <li>We need to perform a contract with you</li>
                <li>You have given us permission to do so</li>
                <li>
                    The processing is in our legitimate interests and it's not
                    overridden by your rights
                </li>
                <li>For payment processing purposes</li>
                <li>To comply with the law</li>
            </ul>
            <h2>Retention of Data</h2>
            <p>
                We will retain your Personal Data only for as long as it is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations, resolve disputes, enforce
                our legal agreements and policies, and for internal analysis
                purposes.
            </p>
            <br />
            <p>
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period of time,
                except when this data is used to strengthen the security or to
                improve the functionality of our Service, or we are legally
                obligated to retain this data for longer periods.{" "}
            </p>
            <br />
            <p>
                If you are a registered user of our Service, we will retain your
                Personal Data for the duration of your membership or as long as
                necessary to provide you with our Service. If you wish to cancel
                your account or request that we no longer use your information
                to provide you with our Service, please contact us at:&nbsp;
                <a href="mailto:contact@creatoraitools.com" className="">
                    contact@creatoraitools.com
                </a>
                . We will respond to your request within a reasonable timeframe.
                Please note that some or all of the information you provide may
                be required in order for the Service to function properly or for
                us to comply with legal obligations, and we may be required to
                retain certain information by law even after you request its
                deletion.
            </p>
            <br />
            <p>
                {" "}
                We may also retain certain information for internal purposes
                such as auditing, accounting, billing and other business-related
                purposes.
            </p>
            <h2>How We Protect Your Information</h2>
            <p>
                We take appropriate technical and organizational measures to
                protect the personal data that we collect and process. This
                includes using SSL encryption for all internet traffic and
                storing sensitive data securely.
            </p>
            <h2>Your Rights</h2>
            <p>
                You have the right to access, correct, and delete your personal
                data. You also have the right to object to the processing of
                your personal data, to restrict the processing of your personal
                data, and to receive your personal data in a structured,
                commonly used, and machine-readable format.
            </p>
            <h2>Disclosure of Personal Information</h2>
            <p>
                We do not sell, rent, or lease your personal information to any
                third parties. However, we may disclose your personal
                information as follows:
            </p>
            <br />
            <ul>
                <li>
                    <span>
                        <b>Service Providers:</b>
                    </span>{" "}
                    We may share your personal information with third-party
                    service providers who perform functions on our behalf, such
                    as hosting.
                </li>
                <li>
                    <span>
                        <b>Business Transfers:</b>
                    </span>{" "}
                    We may transfer your personal information in connection with
                    a merger, acquisition, or sale of all or a portion of our
                    assets.
                </li>
                <li>
                    <span>
                        <b>Legal Compliance:</b>
                    </span>{" "}
                    We may disclose your personal information if required to do
                    so by law or in response to a subpoena, court order, or
                    other valid legal process.
                </li>
                <li>
                    <span>
                        <b>Protection of Rights:</b>
                    </span>{" "}
                    We may disclose your personal information when we believe in
                    good faith that disclosure is necessary to protect our
                    rights, protect your safety or the safety of others,
                    investigate fraud, or respond to a government request.
                </li>
            </ul>
            <h2>Data Security</h2>
            <p>
                We take reasonable measures to protect the security of your
                personal information from unauthorized access, use, or
                disclosure. However, no data transmission over the internet or
                any wireless network can be guaranteed to be 100% secure.
                Therefore, while we strive to protect your personal information,
                you acknowledge that there are security and privacy limitations
                inherent to the internet which are beyond our control, and we
                cannot guarantee the security, integrity, or privacy of any
                information transmitted to or from our website.
            </p>
            <h2>Your Choices</h2>
            <p>
                You may choose not to provide us with certain personal
                information, but that may result in you being unable to use
                certain features of our website or services. You can also choose
                to opt-out of certain types of communication from us, such as
                marketing emails.
            </p>
            <br />
            <p>
                If you are located in the European Economic Area (EEA), you have
                certain data protection rights under the General Data Protection
                Regulation (GDPR). These include the right to access, rectify,
                or erase your personal information, the right to restrict
                processing, the right to data portability, and the right to
                object to processing. You can exercise these rights by deleting
                all your data from the settings page, or by contacting us using
                the contact information provided below.
            </p>
            <h2>Changes to this Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. We will let you know via email and/or a prominent
                notice on our Service, prior to the change becoming effective
                and update the "effective date" at the top of this Privacy
                Policy. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have any questions about this Privacy Policy, please
                contact us at:&nbsp;
                <a href="mailto:contact@creatoraitools.com" className="">
                    contact@creatoraitools.com
                </a>
            </p>
            <br />
            <ul>
                <li>Daniel Anderson</li>
                <li>8405 N Standard St.</li>
                <li>Spokane, WA 99208</li>
            </ul>
            <br />
            <p>
                By using our website or services, you acknowledge that you have
                read and understood this privacy policy and consent to the
                collection, use, and disclosure of your personal information as
                described in this policy.
            </p>
        </div>
    );
}
