import { Modal, Button, Spinner } from "react-bootstrap";

export default function ConfirmSendComponent({
  showModal,
  handleCancel,
  handleConfirm,
  submitMessage,
  progressMessage,
  title,
  inProgress,
}) {
  return (
    <Modal centered show={showModal} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{inProgress ? "Sending" : title}</Modal.Title>
      </Modal.Header>
      {inProgress ? (
        <>
          <Modal.Body>{progressMessage?.length > 0 && progressMessage}</Modal.Body>
          <Modal.Footer><Spinner animation="border" variant="primary" /></Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              {submitMessage}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
