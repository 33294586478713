import { useEffect, useState } from "react";
import AutoresizingTextArea from "./util/AutoresizingTextArea";
import {
    Alert,
    Button,
    Card,
    Container,
    Dropdown,
    Form,
    Modal,
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useAuth } from "../security/AuthContext";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getSettings, postSettings } from "../api/YoutubeService";
import LoadingSmallComponent from "./util/LoadingSmallComponent";

export default function ChannelSettingsModal({ show, onClose }) {
    const auth = useAuth();
    const [searchParams] = useSearchParams();

    const initialSettings = {
        myPersonality: "",
        myLikes: "",
        myIdentity: "",
        myEmojis: "",
        maxCommentsPerPage: 5,
        openAIConsent: false,
    };

    const [settings, setSettings] = useState(null);

    const channelId = searchParams.get("channel");
    const [isSaving, setIsSaving] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleGPTConsentChange = (e) => {
        const newSettings = {
            ...settings,
            openAIConsent: e.target.checked,
        };

        setSettings(newSettings);
    };

    const handleSelectCommentsPerPage = (value) => {
        const newSettings = {
            ...settings,
            maxCommentsPerPage: value,
        };

        setSettings(newSettings);
    };

    useEffect(() => {
        if (channelId != null) {
            getSettings(channelId)
                .then((response) => {
                    setSettings(response.data);
                    setIsComplete(checkIfInputComplete(response.data));
                })
                .catch((error) => {
                    console.log("Error loading settings" + error);
                });
        }
    }, []);

    const handleSettingChange = (name, value) => {
        const newSettings = {
            ...settings,
            [name]: value,
        };

        setSettings(newSettings);
    };

    function saveSettings() {
        if (checkIfInputComplete(settings)) {
            setErrorMessage(null);
            setIsSaving(true);
            postSettings(channelId, settings)
                .then((response) => {
                    setIsSaving(false);
                    setIsComplete(true);
                    onClose();
                    console.log("saved settings");
                })
                .catch((error) => {
                    setIsSaving(false);
                    console.log("Failed to post settings" + error);
                });
        } else {
            if (settings["openAIConsent"]) {
                setErrorMessage(
                    "Please enter a value for each AI personality setting"
                );
            } else {
                setErrorMessage(
                    "To generate AI replies, we'll have to send your data to OpenAI. Please consent to sharing your comment data with OpenAI."
                );
            }
        }
    }

    const settingItems = [
        {
            settingName: "Your Identity",
            exampleText: "vegan",
            settingKey: "myIdentity",
            maxLength: 250,
        },
        {
            settingName: "Your Personality",
            exampleText:
                "a girl adventuring happily through South America in a van",
            settingKey: "myPersonality",
            maxLength: 250,
        },
        {
            settingName: "Your Likes",
            exampleText: "hikes and bike rides with pets, traveling",
            settingKey: "myLikes",
            maxLength: 250,
        },
        {
            settingName: "Your Favorite Emojis",
            exampleText: "💜🙃",
            settingKey: "myEmojis",
            maxLength: 20,
        },
    ];

    function checkIfInputComplete(settingsPassedIn) {
        for (let item of settingItems) {
            const settingValue = settingsPassedIn[item.settingKey];

            if (
                !settingValue ||
                settingValue.length === 0 ||
                settingValue.length > item.maxLength
            ) {
                return false;
            }
        }

        return settingsPassedIn["openAIConsent"];
    }

    function onHide() {
        if (isComplete) {
            onClose();
        }
    }

    const InnerComponent = () => {
        return (
            <Dropdown
                onSelect={(selectedKey) =>
                    handleSelectCommentsPerPage(selectedKey)
                }>
                <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                    {settings["maxCommentsPerPage"]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item eventKey="5">5</Dropdown.Item>
                    <Dropdown.Item eventKey="10">10</Dropdown.Item>
                    <Dropdown.Item eventKey="20">20</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const GPTConsentComponent = () => {
        return (
            <Container>
                <Form>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <Form.Check
                                className="big-checkbox"
                                type="checkbox"
                                id="gpt-consent-checkbox"
                                checked={settings["openAIConsent"]}
                                onChange={handleGPTConsentChange}
                            />
                        </Col>
                        <Col>
                            <Form.Label
                                htmlFor="gpt-consent-checkbox"
                                className="mb-0">
                                I consent to share my YouTube comments with
                                OpenAI's GPT-4 for response generation as
                                outlined in the{" "}
                                <a
                                    href="/privacy"
                                    target="_blank"
                                    rel="noreferrer">
                                    Privacy Policy
                                </a>{" "}
                                and{" "}
                                <a
                                    href="/terms"
                                    target="_blank"
                                    rel="noreferrer">
                                    Terms of Service
                                </a>
                            </Form.Label>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    };

    return (
        <div className="container">
            {/*auth.checkIfSubscribed() &&*/ channelId != null && (
                <Modal centered show={show} onHide={onHide} size="xl">
                    <Modal.Header closeButton={isComplete}>
                        <Modal.Title>Channel AI Settings</Modal.Title>
                    </Modal.Header>
                    {settings && (
                        <Modal.Body>
                            <Row xs={1} md={2} className="g-4">
                                {settingItems.map((item) => (
                                    <SingleProfileComponent
                                        key={item.settingKey}
                                        settingName={item.settingName}
                                        exampleText={item.exampleText}
                                        settingValue={settings[item.settingKey]}
                                        onChange={(value) =>
                                            handleSettingChange(
                                                item.settingKey,
                                                value
                                            )
                                        }
                                        maxLength={item.maxLength}
                                    />
                                ))}
                            </Row>
                            <SettingComponent
                                xs={12}
                                sm={12}
                                md={10}
                                lg={10}
                                name="Comments per Page"
                                tooltip="How many AI replies
                                                            would you like to
                                                            review per page?"
                                BodyComponent={
                                    InnerComponent
                                }></SettingComponent>
                            <SettingComponent
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                name="GPT-4 Consent"
                                BodyComponent={
                                    GPTConsentComponent
                                }></SettingComponent>

                            {errorMessage != null && (
                                <Alert className="text-center" variant="danger">
                                    {errorMessage}
                                </Alert>
                            )}
                        </Modal.Body>
                    )}
                    {settings && (
                        <Modal.Footer>
                            {isSaving ? (
                                <LoadingSmallComponent />
                            ) : (
                                <Button
                                    variant="primary"
                                    onClick={saveSettings}>
                                    Save
                                </Button>
                            )}
                        </Modal.Footer>
                    )}
                </Modal>
            )}
        </div>
    );
}

function SettingComponent({ name, tooltip, BodyComponent, xs, sm, md, lg }) {
    return (
        <Row
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            className="mt-2 justify-content-center">
            <Col className="">
                <Card className="m-4 text-center">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <>
                            {name}
                            {tooltip && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            <strong>{tooltip}</strong>
                                        </Tooltip>
                                    }>
                                    <Button variant="secondary">?</Button>
                                </OverlayTrigger>
                            )}
                        </>
                    </Card.Header>
                    <Card.Body>
                        <BodyComponent />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function SingleProfileComponent({
    settingName,
    settingValue,
    onChange,
    exampleText,
    maxLength,
}) {
    return (
        <Col>
            <Card className="m-2 text-center">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <>
                        {settingName}
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    <strong>
                                        Please follow the format of this
                                        example:{" "}
                                    </strong>
                                    {exampleText}
                                </Tooltip>
                            }>
                            <Button variant="secondary">?</Button>
                        </OverlayTrigger>
                    </>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <AutoresizingTextArea
                            inputText={settingValue}
                            onChange={onChange}
                            placeholder={exampleText}
                            maxLength={maxLength}></AutoresizingTextArea>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
}
