import { useState } from "react";

export default function ApproveComponent( { comment, approvalHasChanged} ) {

    const [isApproved, setIsApproved] = useState(!!comment.isApproved);

    function handleCheckboxChange(event) {
        const isChecked = event.target.checked;
  
        handleStateChange(isChecked);
    }

    function handleApproveTap() {
        const newValue = !isApproved; 
        handleStateChange(newValue);
    }

    function handleStateChange(newValue) {
        comment.isApproved = newValue;
        approvalHasChanged();
        
        setIsApproved(newValue);
    }

    return (
        <div className="commentsTableColumn5 d-flex align-items-center justify-content-center">
            <div className="mobile-only">
                <button className={`approve-comment-button ${isApproved ? "approve-comment-button-selected" : ""}`} onClick={handleApproveTap}>{isApproved ? "Approved" : "Approve?"}</button>
            </div>
            <div className="non-mobile-only">
                <input className="big-checkbox" type="checkbox" checked={isApproved} onChange={handleCheckboxChange} />
            </div>
        </div>   
    )
}