import { Navbar } from "react-bootstrap";

export default function CommentReplyTableHeader({tableInfo}) {
  return (
    <Navbar sticky="top" className="bg-light border-bottom mb-3 hide-on-mobile-flex">
        {tableInfo.map((item, index) => { 
          const derivedClassName = `commentsTableColumn${index + 1}`;
          return  <div className={derivedClassName} key={index}><div classname="header-text">{item}</div></div>
        })}
    </Navbar>
  );
}