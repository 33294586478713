import { API_BASE_URL } from '../constants/constants';

import axios from "axios";

export const apiClient = axios.create(
    {
        baseURL: API_BASE_URL ,

        headers: {
            common: {        // can be common or any other method
              'Content-Type': 'application/json'
            }
          }
    }
);

