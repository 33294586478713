
import styles from '../css/landingpage.module.css';

import { useInView } from "react-intersection-observer"

// import developedWithYoutube from "../../assets/developed-with-youtube.png";

import mockup_mac from "../../assets/mockup_mac_small.png";
import mockup_phone from "../../assets/mockup_phone.png"

// import bolt from "../../assets/bolt-solid.svg"
import bolt from "../../assets/amplify.png"
//  import comments from "../../assets/comments-solid.svg"
 import comments from "../../assets/your-personality.png"
import happy from "../../assets/superfans.png"
// import happy from "../../assets/face-laugh-beam-solid.svg"
import openAi from "../../assets/openai-logomark.svg";
import onea from "../../assets/1a.png";
import languages from "../../assets/languages.svg";

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../security/AuthContext";

export default function MarketingComponent() {
    const isMobile = window.innerWidth <= 750;

    const { pathname, hash, key } = useLocation();

    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate("/welcome");
        }
    }, []);

    useEffect(() => {
        if (hash === "") {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    const [animation0Ref, inView0] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const [unleashRef, inViewUnleash] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.2 : 0.8,
    });

    const [unleash2Ref, inViewUnleash2] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.2 : 0.8,
    });

    const [animation1_1Ref, inView1_1] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.5 : 0.8,
    });

    const [animation1_2Ref, inView1_2] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.5 : 0.8,
    });

    const [animation1_3Ref, inView1_3] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.5 : 0.8,
    });


    const [animation1_1Ref2, inView1_12] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.5 : 0.8,
    });

    const [animation1_2Ref2, inView1_22] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.5 : 0.8,
    });

    const [animation1_3Ref2, inView1_32] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.5 : 0.8,
    });

    const [animation2_1Ref, inView2_1] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.5 : 0.5,
    });

    const [animation2_2Ref, inView2_2] = useInView({
        triggerOnce: true,
        threshold: isMobile ? 0.3 : 0.5,
    });

    const [animation3Ref, inView3] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    function openSignup() {
        navigate("/signup");
    }

    return (
        <div className={`container-fluid ${styles["marketing-container"]}`}>
            <section
                className={`${styles["hero-section"]} align-items-center ${
                    styles["marketing-underlay"]
                } ${
                    inView0
                        ? `${styles.animate0} ${styles.animation0}`
                        : styles.animation0
                }`}
                ref={animation0Ref}>
                <h1 className={"mt-4 " + styles["marketing-h1"]}>
                    AI-Powered Replies
                </h1>
                <img
                    className={styles["mac-mockup"]}
                    alt="Mac Mockup"
                    src={mockup_mac}
                />
                <p className={`${styles["hero-text"]} mb-4 mt-4`}>
                    Commentors who connect <b className="text-warning">with you</b> help your YouTube
                    succeed!
                </p>
                <div style={{ "--order": 8 }}>
                    <button
                        onClick={openSignup}
                        className={
                            styles["call-to-action"] +
                            " border border-white m-3"
                        }>
                        Get Started 🚀
                    </button>
                </div>
            </section>
            <section className={styles["marketing-section-features"]}>
                <h1
                    ref={unleash2Ref}
                    className={`${styles["small-h1"]} ${
                        styles["fade-in-animation"]
                    } ${inViewUnleash2 ? styles["fade-in-animate"] : " "}`}>
                    Unleash the <strong className="text-danger">Power of AI</strong>
                </h1>
                <div className={styles["marketing-top-grid"]}>
                    <div
                        ref={animation1_1Ref2}
                        className={
                            inView1_12
                                ? `${styles.animation1} ${styles.animate1}`
                                : styles.animation1
                        }
                        style={{ "--order": 1 }}>
                        <img
                            className={styles["grid-icons"]}
                            alt="Open AI"
                            src={openAi}></img>
                        <h2>OpenAI API</h2>
                        <p className={styles["small-text"]}>
                            Powered by GPT-4. 
                            <br />
                            The best tech in AI
                        </p>
                    </div>
                    <div
                        ref={animation1_2Ref2}
                        className={
                            inView1_22
                                ? `${styles.animation1} ${styles.animate1}`
                                : styles.animation1
                        }
                        style={{ "--order": 2 }}>
                        <img
                            className={styles["grid-icons"]}
                            alt="1a"
                            src={onea}></img>
                        <h2>YouTube API</h2>
                        <p className={styles["small-text"]}>
                            Quicker and easier
                            <br /> than TubeBuddy's Plugin
                        </p>
                    </div>
                    <div
                        ref={animation1_3Ref2}
                        className={
                            inView1_32
                                ? `${styles.animation1} ${styles.animate1}`
                                : styles.animation1
                        }
                        style={{ "--order": 3 }}>
                        <img
                            className={styles["grid-icons"]}
                            alt="Languages"
                            src={languages}></img>
                        <h2>Polyglot</h2>
                        <p className={styles["small-text"]}>
                            Our Artificial Intelligence can
                            <br /> reply in over 100 languages
                            <br />
                        </p>
                    </div>
                </div>
            </section>
            <section
                className={`${styles["marketing-section-3"]}`}>
                <img
                    ref={animation2_1Ref}
                    className={
                        inView2_1
                            ? `${styles.animate2} ${styles.animation2}`
                            : styles.animation2
                    }
                    src={mockup_phone}
                    alt="Mockup Phone"
                    width="250px"
                    style={{ "--sign": -1 }}></img>
                <div
                    ref={animation2_2Ref}
                    className={
                        inView2_2
                            ? `${styles.animate2} ${styles.animation2}`
                            : styles.animation2
                    }
                    style={{ "--sign": 1 }}>
                    <h1 className={`mb-4 ${styles["small-h1"]}`}>
                        Powerful <br />& Smooth
                    </h1>
                    <p className={`mb-5 ${styles["small-text"]}`}>
                        <strong>①</strong> Get an AI Reply
                        <br />
                        <strong>②</strong> Edit the Reply <br />
                        <strong>③</strong> Approve and Send!
                    </p>
                    <button
                        onClick={openSignup}
                        className={"border border-white " + styles["call-to-action"]}>
                        Try Now
                    </button>
                </div>
            </section>
            <section className={styles["marketing-section-2"]}>
                <h1
                    ref={unleashRef}
                    className={`${styles["small-h1"]} ${
                        styles["fade-in-animation"]
                    } ${inViewUnleash ? styles["fade-in-animate"] : ""}`}>
                    <strong className="text-danger">LESS</strong> commenting <strong className="text-danger">MORE</strong> creating
                </h1>
                <div className={styles["marketing-top-grid"]}>
                    <div
                        ref={animation1_1Ref}
                        className={
                            inView1_1
                                ? `${styles.animation1} ${styles.animate1}`
                                : styles.animation1
                        }
                        style={{ "--order": 1 }}>
                        <img
                            className={styles["grid-icons"]}
                            alt="bolt"
                            src={bolt}></img>
                        <h2>Amplify</h2>
                        <p className={styles["small-text"]}>Reply to comments not shown in <br />
                            YouTube Studio</p>
                    </div>
                    <div
                        ref={animation1_2Ref}
                        className={
                            inView1_2
                                ? `${styles.animation1} ${styles.animate1}`
                                : styles.animation1
                        }
                        style={{ "--order": 2 }}>
                        <img
                            className={styles["grid-icons"]}
                            alt="comments"
                            src={comments}></img>
                        <h2>Your Personality</h2>
                        <p className={styles["small-text"]}>
                            Settings for your identity, 
                            <br />
                            personality, likes, and favorite emojis
                        </p>
                    </div>
                    <div
                        ref={animation1_3Ref}
                        className={
                            inView1_3
                                ? `${styles.animation1} ${styles.animate1}`
                                : styles.animation1
                        }
                        style={{ "--order": 3 }}>
                        <img
                            className={styles["grid-icons"]}
                            alt="happy"
                            src={happy}></img>
                        <h2>Create Superfans</h2>
                        <p className={styles["small-text"]}>
                            Gain vocal, sharing viewers
                            <br /> without the emotional drain.
                        </p>
                    </div>
                </div>
            </section>
            <section id="pricing" className={styles["marketing-section-4"]}>
                <h1
                    className={
                        styles["small-h1"] + " " + styles["marketing-underlay"]
                    }>
                    Great Low Pricing
                </h1>
                <div
                    ref={animation3Ref}
                    className={`${
                        styles["pricing-options"]
                    } py-4x mt-5 rounded border border-white ${
                        inView3 ? styles.animate3 : ""
                    } ${styles.animation3}`}>
                    <h2 className={`${styles["pricing-name"]} mt-4`}>MEMBER</h2>
                    <p className={`${styles["small-pricing-label"]} m-2`}>
                            <span className={"text-danger " + styles["big-number"]}><del>19</del></span>
                            <del className='text-danger'>.99 /mo.</del>
                            <br />
                            <div className="text-warning">Early Adopter Discount!</div>
                            
                            <span className={styles["big-number"]}>9</span>
                            .99 /mo.
                        </p>
                    <p>Up to 1,500,000 Tokens</p>
                    <p>(about 3,000 Replies)</p>
                    <p>Unlimited Channels</p>
                    <button
                        onClick={openSignup}
                        className={`${styles["call-to-action"]} m-4 p-1 border border-white`}>
                        <h4>Sign Up</h4>
                    </button>
                </div>
            </section>
        </div>
    );
}